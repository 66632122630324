import heroCarousel from '~/components/Organisms/Organic/HeroCarousel/HeroCarousel.vue'
import hotOrganicButton from '~/components/Atoms/Organic/Button/Button.vue'
import hotIcon from '~/components/Molecules/Icon/Icon.vue'
import modalSignup from '~/mixins/modalSignup.js'
import stripe from '~/mixins/stripe.js'
import device from '~/mixins/device.js'

export default {
  mixins: [device, modalSignup, stripe],

  props: {
    leftSideTestAB: Object,
    leftSide: Object,
    rightSide: Object,
    hasTopStripe: Boolean,
    isVariationCampaign: String,
    isVariationSignupEmbed: Boolean,
    locale: String,
    route: Object,
    campaign: String,
  },

  components: {
    signupIframe: () => import('~/components/Molecules/OrganicSquad/SignupIframe/SignupIframe.vue'),
    heroCarousel,
    hotOrganicButton,
    hotIcon,
  },

  data() {
    return {
      intervalID: null,
      typePhrase: this.leftSide.phrases[0],
      isTransitioning: false,
      index: 0,
      startTime: null,
      duration: 3500,
      isAnimating: true,
    }
  },

  mounted() {
    this.startTime = performance.now()
    this.animate()
  },

  beforeDestroy() {
    cancelAnimationFrame(this.intervalID)
  },

  computed: {
    hasChecklist() {
      return this.leftSide.hasOwnProperty('checklist')
    },

    isPortuguesePage() {
      return Boolean(['pt-br'].includes(this.$i18n.locale))
    },

    isNewSpanishHomeTest() {
      if (['es-es'].includes(this.$i18n.locale)) {
        return this.$growthbook.isOn('7_corporate_website_home_all_devices_spain_new_es_es_home_layout')
      }

      return false
    },

    shouldRenderSignupWithTitle() {
      return this.isPortuguesePage || this.isNewSpanishHomeTest
    },

    topStripeClass() {
      if (this.hasTopStripe) return 'with--topStripe'

      return ''
    },
  },

  methods: {
    animate(timestamp) {
      if (!this.startTime) this.startTime = timestamp
      const elapsedTime = timestamp - this.startTime

      const progress = parseFloat((elapsedTime / this.duration).toFixed(2))

      if (progress < 1 && this.isAnimating) {
        this.intervalID = requestAnimationFrame(this.animate)
      } else {
        this.startTime = timestamp
        this.updateWord(progress)
        this.intervalID = requestAnimationFrame(this.animate)
      }
    },

    async updateWord() {
      const lengthWords = this.leftSide.phrases.length
      this.index++

      if (this.index >= lengthWords) {
        this.index = 0
      }
    },
  },
}
